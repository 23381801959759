import React from "react";
import SearchAPI from "./SearchApi";

function App() {
  return (
    <div className="App">
      <SearchAPI />
    </div>
  );
}

export default App;
